import Box from "@mui/material/Box";
import {ReactNode} from "react";
import {SxProps} from "@mui/material";


const FormBox = (props: { children: ReactNode, width?: string, sx?: SxProps}) => {

    const width = props.width ? props.width : '25ch';

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: width }, ...props.sx
            }}
            noValidate
            autoComplete="off"
            onSubmit={e => e.preventDefault()}
        >
            {props.children}
        </Box>
    )

}

export default FormBox;