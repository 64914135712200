import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useAuthService} from '../../services/useAuthService';
import jwt_decode from 'jwt-decode';
import DecodedTokenInterface from "../../interfaces/DecodedTokenInterface";
import UserInterface from '../../interfaces/UserInterface';
import {AuthContextInterface} from "./AuthContextInterface";

let localStorageToken = localStorage.getItem('token')
    // @ts-ignore
    ? JSON.parse(localStorage.getItem('token'))
    : null;

let localStorageUser = localStorage.getItem('current_user')
    // @ts-ignore
    ? JSON.parse(localStorage.getItem('current_user'))
    : null;

let initialDecodedToken: DecodedTokenInterface = localStorageToken ? jwt_decode(localStorageToken) : {
    iat: 1649844891,
    exp: 1649845493,
    roles: [
        '',
    ],
    username: 'deconnecté',
    prenom: 'Déco',
    nom: 'Necté',
};

const AuthContext = createContext<AuthContextInterface>({
    token: null,
    decodedToken: null,
    user: null,
    setToken: (newToken: string) => {
    },
    replaceConnectedUser: (userMaj: UserInterface) => {
    },
    logout: () => {
    },
    hasRole: (role: string) => {
        return false
    },
    setLoadingUser: (arg0: boolean) => {
    },
    setUser: (arg0: UserInterface | null) => {
    },
});

export interface IAuthProvider {
    children: ReactNode
}

export const AuthProvider: React.FC<IAuthProvider> = ({children}) => {
    const [token, setNewToken] = useState<string | null>(localStorageToken);
    const [decodedToken, setDecodedToken] = useState<DecodedTokenInterface | null>(initialDecodedToken);
    const [user, setUser] = useState<UserInterface | null>(localStorageUser);
    const [loadingUser, setLoadingUser] = useState<boolean>(false);
    const [expirationTimeout, setExpirationTimeout] = useState<NodeJS.Timeout | null>(null);

    /**
     * Vérification de la validité du token
     */
    const checkExpiration = () => {
        if (token && decodedToken && decodedToken.exp < Date.now() / 1000) {
            logout();
        } else {
            setExpirationCheckTimeout();
        }
    };

    /**
     * lancement d'un timeout pour vérifier la validité du token
     * reset si un timeout est en cours
     */
    const setExpirationCheckTimeout = () => {
        if (expirationTimeout) {
            clearTimeout(expirationTimeout);
        }
        if (decodedToken === null) {
            return;
        }
        const expirationIn = decodedToken.exp - (Date.now() / 1000) + 1;
        setExpirationTimeout(setTimeout(() => checkExpiration(), expirationIn * 1000));
    };

    /**
     * remplacement du token
     * @param newToken
     */
    const setToken = (newToken: string | null) => {
        let decoded_token = null;
        if (newToken) {
            decoded_token = jwt_decode<DecodedTokenInterface>(newToken);
        }
        setDecodedToken(decoded_token);
        setNewToken(newToken);
        if (newToken) {
            localStorage.setItem('token', JSON.stringify(newToken));
        } else {
            localStorage.removeItem('token');
        }
    };

    /**
     * remplacement de l'utilisateur en cours
     * @param userMaj
     */
    const replaceConnectedUser = (userMaj: UserInterface | null) => {
        setUser(userMaj);
        if (userMaj) {
            localStorage.setItem('current_user', JSON.stringify(userMaj));
        } else {
            localStorage.removeItem('current_user');
        }
    };

    /**
     * Déconnexion
     */
    const logout = () => {
        replaceConnectedUser(null);
        setToken(null);
    };

    /**
     * Vérifie si l'utilisateur connecté à un role précis ou un parmis un tableau
     * @param role
     * @returns {boolean|*}
     */

    const hasRole = (role: string): boolean => {
        if (!decodedToken) {
            return false;
        }
        if (Array.isArray(role)) {
            return role.some(r => decodedToken.roles.includes(r));
        }
        return decodedToken.roles.includes(role);
    };

    const authService = useAuthService({
        token,
        decodedToken,
        user,
        hasRole,
        setToken,
        replaceConnectedUser,
        logout,
        setLoadingUser,
        setUser
    });

    /**
     * Rechargement user
     */
    const majUser = () => {
        if (token === null) {
            return replaceConnectedUser(null);
        }
        if (user === null && !loadingUser) {
            authService.loadCurrentUser();
        }
    };

    /**
     * si changement de token, on recharge les droits et l'utilisateur
     */
    useEffect(() => {
        majUser();
        setExpirationCheckTimeout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    /**
     * si l'utilisateur passe à null et qu'on a un token
     * on recharge l'utilisateur
     */
    useEffect(() => {
        if (user === null && token !== null) {
            majUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    /**
     * Provider
     */
    return (
        <AuthContext.Provider value={{
            token,
            decodedToken,
            user,
            hasRole,
            setToken,
            replaceConnectedUser,
            logout,
            setLoadingUser,
            setUser
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    return useContext(AuthContext);
};

