import React, {ReactNode} from 'react';
import {Button, CircularProgress} from "@mui/material";
import {useFormContext} from "../../../context/form/formContext";

export const FormSubmitButton = (props: {
                                     children?: ReactNode,
                                     className?: string,
                                     variant?: "contained" | "text" | "outlined" | undefined
                                 }
) => {
    const form = useFormContext().form;

    /** Si en cours de sumbission, on afficher un spinner */
    const renderContent = () => {
        if (form?.isSubmiting) {
            return <CircularProgress size={24}/>;
        }
        return props.children ? props.children : 'Valider';
    };

    /** Variant par defaut contained */
    const variant = props.variant ? props.variant : 'contained';

    return (
        <Button onClick={() => form?.handleSubmit()}
                variant={variant}
                size="small"
                color="primary"
                type="submit"
                disabled={form?.isSubmiting}
                className={props.className ? props.className : ''}
        >
            {renderContent()}
        </Button>
    );
};