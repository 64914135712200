import React from 'react';
import MenuGauche from './MenuGauche/MenuGauche';
import Footer from './Footer/Footer';
import {Outlet} from 'react-router-dom';
import {Paper, styled} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MenuHaut from "./MenuHaut/MenuHaut";
import {menusBackgroundColor} from "../../_Theme";

const MainContent = styled(Paper)(({theme}) => ({
    minHeight: '80vh',
    padding: theme.spacing(1),
}));

const PageLayout = () => {
    return (

        <Grid container spacing={2}>
            <MenuHaut/>
            <Grid sx={{display: {xs: 'none', md: 'flex'}, backgroundColor: menusBackgroundColor}}>
                <MenuGauche/>
            </Grid>
            <Grid xs={12} md={10}>
                <MainContent>
                    <Grid container justifyContent="center">
                        <Outlet/>
                    </Grid>
                </MainContent>
                <Footer/>
            </Grid>
        </Grid>
    );
}
export default PageLayout;