import CollectionsIcon from '@mui/icons-material/Collections';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const pagesList = [
    {id: 0, title: 'NFT', path: '/nfts', icon: CollectionsIcon},
    {id: 1, title: 'Collections', path: '/contrats', icon: MenuBookIcon},
    {id: 2, title: 'Utilisateurs', path: '/users', icon: SupervisedUserCircleIcon},
];

export default pagesList;