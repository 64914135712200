import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import {useAuthContext} from "../../../context/auth/authContext";
import {ArrowDropDown} from "@mui/icons-material";
import {Link} from "@mui/material";
import pagesList from "../../../PagesList";
import {menusTextColor} from "../../../_Theme";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from "../../../assets/images/logo.png";

const titre = 'GOAT Stadium';


const MenuHaut = () => {
    const authContext = useAuthContext();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const userMenu = [
        {title: 'Paramètres', path: '/users/' + authContext.user?.id},
        {title: 'Deconnexion', path: '/logout'},
    ];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (<AppBar position="static">
            <Container maxWidth="xl" className={"toolbar"}>
                <Toolbar disableGutters>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <img src={logo} alt={"logo"} width={"24px"}/>
                        &nbsp;
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: menusTextColor,
                                textDecoration: 'none',
                            }}
                        >
                            {titre}
                        </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pagesList.map((page) => (
                                <Link key={page.path} href={page.path} underline="hover" color="inherit" sx={{
                                    "&:hover": {
                                        textDecoration: "none!important",
                                    }
                                }}>
                                    <MenuItem key={page.path} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">
                                            {page.title}
                                        </Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}/>
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Paramètres">
                            <IconButton onClick={handleOpenUserMenu}
                                        sx={{p: 0, color: menusTextColor, fontSize: "16px"}}
                            >
                                <AccountCircleIcon/> {' ' + authContext.user?.prenom + ' ' + authContext.user?.nom}
                                <ArrowDropDown/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {userMenu.map((item) => (
                                <Link key={item.path} href={item.path} underline="hover" color="inherit" sx={{
                                    "&:hover": {
                                        textDecoration: "none!important",
                                    }
                                }}>
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">
                                            {item.title}
                                        </Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default MenuHaut;