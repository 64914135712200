import {AxiosResponse} from 'axios';
import {useApiService} from './api/useApiService';
import {AuthContextInterface} from '../context/auth/AuthContextInterface';

export const useAuthService = (authContext: AuthContextInterface) => {
    const api = useApiService(authContext);

    /**
     * @param credentials
     * @returns {Promise<Response>}
     */
    const loginUser = async (credentials: { username?: string, password?: string }): Promise<AxiosResponse> => {
        const response = await api.post('login_check', credentials);
        if (response.status === 200) {
            authContext.setToken(response.data.token);
        }
        return response;
    }

    /**
     * Chargement de l'utilisateur courrant
     */
    const loadCurrentUser = () => {
        authContext.setLoadingUser(true);
        api.get('current-user').then(response => {
            if (response.status === 200) {
                authContext.replaceConnectedUser(response.data);
            }
            authContext.setLoadingUser(false);
        })
    }

    /**
     * Deconnexion utilisateur
     */
    const logoutUser = () => {
        authContext.logout();
    }

    /**
     * Reset password
     * @param username
     * @returns {Promise<*>}
     */
    const resetPassword = async (username: string): Promise<string> => {
        return api.get('users/' + username + '/reset-mot-de-passe', true);
    }

    /**
     *
     * @param data
     * @returns {Promise<AxiosResponse<*>>}
     */
    const updateUser = async (data: object) => {
        if (!authContext.user) {
            return;
        }
        return api.put('users/' + authContext.user.id, data).then(response => {
            if (response.status === 200) {
                authContext.setUser(null);
            }
            return response;
        });
    }

    return {
        loginUser,
        logoutUser,
        resetPassword,
        loadCurrentUser,
        updateUser,
    }
}
