import Login from "./views/Auth/Login/Login";
import React from "react";
import Home from "./views/Home/Home";

const LazyListeUser = React.lazy(() => import('./views/Users/ListeUsers/ListeUsers'));
const LazyLectureUser = React.lazy(() => import('./views/Users/LectureUser/LectureUser'));
const LazyLogout = React.lazy(() => import('./views/Auth/Logout'));
const LazyContratsListe = React.lazy(() => import('./views/Contrats/Liste/ListeContrats'));
const LazyContratForm = React.lazy(() => import('./views/Contrats/Forms/ContratForm'));
const LazyNftList = React.lazy(() => import('./views/Nft/Liste/ListeNft'));
const LazyContratLecture = React.lazy(() => import('./views/Contrats/LectureContrat/LectureContrat'));

export interface RoutesIntreface {
    path: string;
    element: React.ReactNode;
    index?: boolean;
    description?: string;
    children?: RoutesIntreface[];
}

export const unprotectedRoutes: RoutesIntreface[] = [
    {
        path: 'login',
        description: 'Page de connexion',
        element: <Login/>,
    }
]

export const protectedRoutes: RoutesIntreface[] = [
    {
        path: 'logout',
        description: 'Déconnexion',
        element: <LazyLogout/>,
    },
    {
        path: '',
        index: true,
        description: 'Accueil',
        element: <Home/>,
    },
    {
        path: 'users',
        description: 'Liste des utilisateurs',
        element: <LazyListeUser/>,
    },
    {
        path: 'users/:id',
        description: 'Lecture d\'un utilisateur',
        element: <LazyLectureUser/>,
    },
    {
        path: 'contrats',
        description: 'Liste des contrats',
        element: <LazyContratsListe/>,
    },
    {
        path: 'contrats/new',
        description: 'Nouveau Contrat',
        element: <LazyContratForm/>,
    },
    {
        path: 'contrats/:id',
        description: 'Lecture d\'un contrat',
        element: <LazyContratLecture/>,
    },
    {
        path: 'nfts',
        description: 'Liste des NFT',
        element: <LazyNftList/>,
    }

];