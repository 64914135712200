import {createTheme} from "@mui/material";

const mainBackgroundColor = "#eee";
export const menusBackgroundColor = "#333";
export const menusTextColor = "#eee";

const _Theme = createTheme({
    palette: {
        background: {
            default: mainBackgroundColor,
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: menusBackgroundColor,
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: menusBackgroundColor,
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: menusTextColor,
                },
                selected: {
                    color: menusTextColor,
                    indicator: {
                        backgroundColor: menusBackgroundColor,
                    },
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: menusBackgroundColor,
                    color: menusTextColor,
                }
            }
        }
    }
});

export default _Theme;